import _component from "./component";
import _singleton from "./singleton";
import _hook from "./hook";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var component_1 = _component;
exports.styleSingleton = component_1.styleSingleton;
var singleton_1 = _singleton;
exports.stylesheetSingleton = singleton_1.stylesheetSingleton;
var hook_1 = _hook;
exports.styleHookSingleton = hook_1.styleHookSingleton;
export default exports;
export const __esModule = exports.__esModule,
      styleSingleton = exports.styleSingleton,
      stylesheetSingleton = exports.stylesheetSingleton,
      styleHookSingleton = exports.styleHookSingleton;