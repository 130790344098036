import _react from "react";
import _singleton from "./singleton";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var React = _react;
var singleton_1 = _singleton;

exports.styleHookSingleton = function () {
  var sheet = singleton_1.stylesheetSingleton();
  return function (styles) {
    React.useEffect(function () {
      sheet.add(styles);
      return function () {
        sheet.remove();
      };
    }, []);
  };
};

export default exports;